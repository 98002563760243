<template>
  <div
    :style="
      'display: flex;align-items: center;flex-direction: column;overflow-y:scroll;overflow-x:hidden;height:' +
      hei
    "
  >
    <div
      style="
        display: flex;
        flex-direction: column;
        margin-top: 2vh;
        align-items: center;
      "
    >
      <div
        style="
          width: 100vw;
          display: flex;
          justify-content: end;
          justify-content: flex-end;
          margin-right: 5vw;
        "
      >
        <el-dropdown
          size="medium"
          placement="bottom"
          @command="handleClickLocale"
        >
          <i
            class="iconfont icon-locale"
            style="cursor: pointer; font-size: 25px; color: rgba(0, 66, 166, 1)"
          ></i>
          <el-dropdown-menu slot="dropdown" class="menu-item">
            <el-dropdown-item command="zh">简体中文</el-dropdown-item>
            <el-dropdown-item command="en" divided>English </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <img
        src="../../assets/img/phoneLoginLogo.png"
        alt=""
        style="width: 80vw; margin-top: 6vh; margin-bottom: 10vh"
      />
    </div>
    <div class="subtitle">{{ $t("addLogin.register.title") }}</div>
    <div class="r2 router">
      <span>{{ $t("addLogin.forgetPsd.title2") }}&nbsp;</span>
      <router-link
        to="/phoneloginp"
        class="clear"
        style="display: flex; align-items: center"
      >
        <p>{{ $t("addLogin.loginEmail.login") }}</p>
      </router-link>
    </div>
    <div class="main">
      <div class="inputbox">
        <div id="space-semantic">
          <div id="embed-wrapper">
            <div class="embed-wrapper">
              <div id="captcha-element"></div>
            </div>
          </div>
        </div>
        <div class="box">
          <div class="input">
            <input
              v-model="mail"
              type="text"
              class="all email"
              :placeholder="$t('addLogin.forgetPsd.place1')"
            />
            <i class="iconfont icon-email frontIcon"></i>
          </div>
          <div class="input">
            <input
              id="code"
              v-model="code"
              :autoComplete="false"
              type="text"
              class="num"
              :placeholder="$t('addLogin.loginr.code')"
            />
            <el-button
              type="primary"
              v-if="!issend"
              class="yzm"
              @click="sendCode"
            >
              <p style="white-space: nowrap">
                {{ $t("addLogin.forgetPsd.getCode") }}
              </p>
            </el-button>
            <el-button type="primary" v-else class="yzm1">
              <p id="yzm1" style="white-space: nowrap">
                {{ $t("addLogin.forgetPsd.message6") }}
              </p>
            </el-button>
          </div>
          <div class="input">
            <input
              id="password"
              v-model="password"
              autoComplete="new-password"
              type="password"
              class="all email"
              :placeholder="$t('addLogin.resetEmail.psd')"
            />
            <i class="iconfont icon-locker frontIcon"></i>
            <i
              v-if="eye"
              class="iconfont icon-pwo backIcon"
              @click="changePwVisible"
            ></i>
            <i
              v-if="!eye"
              class="iconfont icon-pwc backIcon"
              @click="changePwVisible"
            ></i>
            <!-- <img src="../../assets/img/mail.png" style="width: 40px; height: 40px; margin-left: 10px" /> -->
          </div>
          <div class="lobtn">
            <el-button
              class="login_goto"
              type="primary"
              :loading="btnLoading"
              @click="handleLogin"
              >{{ $t("addLogin.loginr.try") }}</el-button
            >
            <div class="agreement-box">
              <input
                type="checkbox"
                name="agreement"
                class="agreement-check"
                v-model="checked"
              />
              <label for="agreement">
                <div class="agreement-text">
                  {{ $t("addLogin.register.agreement") }}&nbsp;
                  <el-link
                    href="https://ms.ytdevops.com/pubKnowledge/85b934dc-d50c-4d73-9f48-9d89dd07c439"
                    Target="_blank "
                    type="primary"
                    >{{ $t("addLogin.register.privacyPolicy") }}</el-link
                  >
                  &nbsp;{{ $t("addLogin.register.and") }}&nbsp;
                  <el-link
                    href="https://ms.ytdevops.com/pubKnowledge/8954eeec-f496-4f2d-84d3-ce62266d5ff7"
                    Target="_blank "
                    type="primary"
                    >{{ $t("addLogin.register.termsOfUse") }}</el-link
                  >
                </div>
              </label>
            </div>
          </div>
        </div>
        <div></div>
      </div>
    </div>
    <div style="margin-top: 30px">
      <third-party-login></third-party-login>
    </div>
  </div>
</template>

<script>
import {
  request_verify_code,
  register_with_email,
} from "@/network/login/index.js";
import { request_joinedProjects } from "@/network/personal/index.js";
import { sessionSave, sessionRead, sessionRemove } from "@/utils/session";
import { getWatermark } from "@/network/watermask";
import { mapActions, mapMutations } from "vuex";
import Cookies from "js-cookie";
import Verify5 from "verify5-html";
import { metadata, PhoneNumberUtil } from "google-libphonenumber";
import thirdPartyLogin from "@/components/thirdPartyLogin";

export default {
  components: {
    thirdPartyLogin,
  },
  data() {
    return {
      btnLoading: false,
      lock: false,
      vercode: "",
      code: "",
      password: "",
      mail: "",
      issend: false,
      timeStop: "",
      hei: "",
      eye: false,
      checked: false,
      verify5Config: {
        name: "v5",
        host: "freetvks2vi2.verify5.com",
        token: "61455cc20ef94a91addae923736105e4",
      },
    };
  },
  computed: {},
  mounted() {
    this.hei = window.innerHeight + "px";
  },
  beforeDestroy() {
    clearInterval(this.timeStop);
  },
  methods: {
    ...mapMutations(["SET_WATERMASK"]),
    ...mapActions([
      "setTestPlan",
      "setVersion",
      "setSprint",
      "setBaseLine",
      "setExecutionStatus",
      "setFileType",
      "setProjectUserList",
      "setStatus",
    ]),
    changePwVisible() {
      if (document.getElementById("password").type == "password") {
        document.getElementById("password").type = "text";
        this.eye = true;
      } else {
        document.getElementById("password").type = "password";
        this.eye = false;
      }
    },
    set_release_obj(pid) {
      let i = pid ? pid : this.get_pid();
      this.setTestPlan(i);
      this.setVersion(i);
      this.setSprint(i);
      this.setBaseLine(i);
      this.setExecutionStatus(i);
      this.setFileType(i);
      this.setProjectUserList(i);
      this.setStatus(i);
    },
    handleClickLocale(command) {
      localStorage.setItem("lang", command);
      window.location.reload();
    },
    handleLogin() {
      const self = this;
      if (self.mail.length < 1) {
        self.$message.warning(self.$t("addLogin.loginr.place1"));
        return false;
      }
      if (self.code.length < 1) {
        self.$message.warning(self.$t("addLogin.forgetPsd.place3"));
        return false;
      }
      if (self.password.length < 1) {
        self.$message.warning(self.$t("addLogin.loginPsd.place"));
        return false;
      }
      if (self.checked === false) {
        self.$message.warning(self.$t("addLogin.loginr.place3"));
        return false;
      }
      let data = {
        verifyCode: self.code,
        // contactPhone: self.countryCodePhone + phonereally,
        email: self.mail,
        password: self.password,
        // phoneVerifyCode: self.phoneVerifyCode,
        inviteMark: this.$route.query.inviteMark
          ? this.$route.query.inviteMark
          : null,
      };
      this.btnLoading = true;
      register_with_email(data)
        .then((res) => {
          //PERF(ZHOU) 或许可以注册后直接登录
          let ruleForm = {
            password: self.password,
            email: self.mail,
          };
          self.$store
            .dispatch("Login_password", ruleForm)
            .then((flag) => {
              if (flag === 1) {
                request_joinedProjects()
                  .then((res) => {
                    self.$store.commit("set_joined_projects", res);
                    self.set_release_obj(res[0].projectId);
                    sessionSave("p_id", res[0].projectId);
                    setTimeout(() => {
                      const backURL = sessionRead("backURL");
                      if (
                        backURL === "null" ||
                        backURL === "" ||
                        typeof backURL === "undefined"
                      ) {
                        self.$router.push({ path: `/home` });
                      } else {
                        sessionRemove("backURL");
                        self.$router.push({ path: backURL });
                      }
                    }, 3000);
                  })
                  .catch(() => {
                    this.btnLoading = false;
                  });

                getWatermark()
                  .then((res) => {
                    self.SET_WATERMASK({
                      waterMaskSwitch: res.switchFlag ? true : false,
                      waterMaskValue: res.content ? res.content : "",
                    });
                  })
                  .catch(() => {
                    this.btnLoading = false;
                  });
              } else {
                this.btnLoading = false;
              }
            })
            .catch(() => {
              this.btnLoading = false;
            });
        })
        .catch((err) => {
          console.log(err);
          this.btnLoading = false;
        });
    },
    sendCode() {
      if (this.mail.length < 1) {
        this.$message.warning(this.$t("addLogin.loginr.place1"));
        return false;
      }
      if (this.check_email()) {
        this.vercode = Math.random().toFixed(6).slice(-6);
        Cookies.set("vercode", this.vercode, {
          expires: 60000 / 864e5,
        });
        request_verify_code(this.mail, "REGISTER").then((res) => {
          this.$message({
            type: "success",
            message: this.$t("addLogin.forgetPsd.message7"),
            duration: "5000",
          });
          this.issend = true;
          let timeo = 180;
          this.timeStop = setInterval(() => {
            timeo--;
            if (timeo > 0) {
              document.getElementById("yzm1").innerText =
                this.$t("addLogin.forgetPsd.message8") +
                timeo +
                this.$t("addLogin.forgetPsd.message9");
            } else {
              timeo = 180;
              this.issend = false;
              document.getElementById("yzm1").innerText = this.$t(
                "addLogin.forgetPsd.getCode"
              );
              document.getElementById("yzm1").innerText = this.$t(
                "addLogin.forgetPsd.getCode"
              );
              clearInterval(this.timeStop);
            }
          }, 1000);
        });
      }
    },
    check_email() {
      let emailRegExp = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
      if (!emailRegExp.test(this.mail)) {
        this.$message.warning(this.$t("addLogin.loginr.place1"));
        return false;
      } else {
        let point1 = this.mail.indexOf("@");
        let cop = this.mail.slice(point1).toLowerCase();
        let bidden = [
          "@163.com",
          "@126.com",
          "@qq.com",
          "@gmail.com",
          "@yeah.net",
          "@sina.com",
          "@139.com",
          "@hotmail.com",
          "@189.cn",
          "@outlook.com",
          "@sohu.com",
          "@sina.cn",
          "@vip.163.com",
          "@vip.126.com",
          "@188.com",
          "@live.cn",
          "@yahoo.com",
          "@tom.com",
          "@aliyun.com",
          "@21cn.com",
          "@msn.com",
          "@foxmail.com",
          "@live.com",
          "@letv.com",
        ];
        if (bidden.indexOf(cop) == -1) {
          return true;
        }
        this.$message.warning(this.$t("addLogin.loginr.place2"));
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.disabled {
  pointer-events: none;
}

.subtitle {
  width: 88vw;
  font-size: 26px;
  font-weight: 700;
  text-align: start;
  color: rgba(56, 56, 56, 1);
}

.num {
  font-size: 16px;
  padding-left: 5vw;
  width: 50vw;
  height: 5.5vh;
  border: 1px solid rgba(195, 195, 195, 1);
  border-radius: 6px;
  outline: none;
}

.email {
  font-size: 16px;
  padding-left: 8vw;
  padding-right: 8vw;
  width: 88vw;
  height: 5.5vh;
  border: 1px solid rgba(195, 195, 195, 1);
  border-radius: 6px;
  outline: none;
}

.clear {
  color: inherit;
  user-select: none;
}

.option-text {
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
}

.yzm {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 5.5vh;
  width: 35vw;
  border: 1px solid rgba(48, 100, 143, 1);

  p {
    font-size: 14px;
    margin: 0;
  }
}

.yzm1 {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 5.5vh;
  width: 35vw;
  border: 1px solid rgba(48, 100, 143, 1);

  //   padding-left: 10px;
  p {
    letter-spacing: 2px;
    font-size: 14px;
    margin: 0;
  }
}

.main {
  width: 100%;
  display: grid;
  grid-template-columns: 100% 60%;
  // border: 2px solid white;
  border-radius: 20px;

  .inputbox {
    width: 100%;
    display: grid;
    grid-template-rows: 10% 80% 10%;
    align-items: center;

    .box {
      margin-top: 3vh;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-bottom: 2.5vh;
    }

    .lobtn {
      width: 88vw;
      display: flex;
      flex-direction: column;
      align-items: center;

      .login_goto {
        font-size: 16px;
        font-weight: 700;
        word-spacing: 10px;
        border-radius: 10px;
        width: 100%;
        // height: 5.5vh;
        padding: 2vh 0;
      }
    }

    .input {
      margin-bottom: 1.7vh;
      width: 88vw;
      position: relative;
      padding-bottom: 0.6vh;
      display: flex;
      justify-content: space-between;
    }

    .all {
      font-size: 16px;
    }

    input::-webkit-input-placeholder {
      padding-left: 3vw;
      font-size: 16px;
      color: rgba(195, 195, 195, 1);
      letter-spacing: 2px;
    }

    .agreement-box {
      margin-top: 1.8vh;
      display: flex;
      width: 100%;

      .agreement-check {
        width: 14px;
        height: 14px;
      }

      .agreement-text {
        display: flex;
        flex-wrap: wrap;
        font-size: 14px;
        color: black;
        text-align: left;

        ::v-deep(a) {
          font-size: 14px;
          color: rgba(48, 100, 143, 1);
        }

        ::v-deep(a:hover) {
          font-size: 14px;
          color: #409eff;
        }
      }

      ::v-deep(.el-checkbox__input) {
        margin-top: 3px;
      }
    }
  }

  .frontIcon {
    position: absolute;
    cursor: pointer;
    color: rgba(195, 195, 195, 1);
    font-size: 16px;
    margin: 2.75vh auto;
    margin-left: 11px;
    transform: translateY(-8px);
  }

  .backIcon {
    position: absolute;
    cursor: pointer;
    right: 19px;
    color: rgba(195, 195, 195, 1);
    font-size: 16px;
    margin: 2.75vh auto;
    transform: translateY(-8px);
  }
}

.router {
  height: 100%;
  width: 88vw;
  text-align: start;
  font-size: 14px;
  display: flex;
  margin-top: 0.5vh;

  span {
    color: rgba(137, 138, 140, 1);
  }

  p {
    margin: 0;
    color: rgba(47, 100, 181, 1);
  }
}
</style>
